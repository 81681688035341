.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Viewport Height */
  width: 100vw; /* Viewport Width */
  overflow: hidden; /* Hide overflow */
  background-color: #000000;
}

.scale-container {
  box-sizing: border-box;
  /*border: 2px solid yellow;*/
}

.section-container {
  /*border: 1px solid blue;*/
  box-sizing: border-box;
  /*width: 1600px; !* Original width of the image *!*/
  /*height: 914px; !* Original height of the image *!*/
  /*background-image: url('../public/assets/01-intro.jpeg');*/
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  /*transition: transform 0.3s ease-in-out;*/
  position: relative;
}

.pgNum {
  position: absolute;
  right: 5px;
  color: white;
  opacity: .5;
}

.image-size{
    width: 1600px;
    height: 914px;
}

.vid-container{
  position: absolute;
  left: 307px;
  top:  205px;
  /*width: 600px;*/
  /*height: 100%;*/
}

.vid-container.vid-right{
  left: 664px;
  top:  286px;
}

.vid-container.vid-left{
  left: 56px;
  top:  285px;
}

video {
  outline: 0;
  margin: auto;
  width: 981px;
  height: auto;
  /*max-height: 100%;*/
}

video.vid-right{
  width: 896px;
}

video.vid-left{
  width: 787px;
}

.footer {
  position: absolute;

  display: flex;
  justify-content: space-between;
  align-items: center;

  bottom: 0px;
  width: 100%;
  height: 110px;
  text-align: center;
  /*background-color: rgba(0, 0, 0, 0.5); !* Semi-transparent background *!*/
  /*padding: 10px;*/
  box-sizing: border-box;
  /*border: 2px solid yellow;*/
}

.footer button {
  margin: 0;
  height: 100px;
  width: 280px;
  opacity: 0;
  cursor: pointer;
}

.footer button:disabled {
  cursor: default; /* This sets the cursor to the default arrow */
}

.hidden {
  visibility: hidden !important;
  display: none !important;
}
